import {useAppSelector} from 'store';

import {Goal, User} from 'types';
import {getGoals} from 'features/goalSlice';

export function useGoals(): Goal[] {
    return (useAppSelector(getGoals) || []);
}

export function useUserGoals(user: User | null): Goal[] {
    const goals = useGoals();

    const userGoals = []
    if (user) {
        for (const goal of goals) {
            for (const owner of goal.owners) {
                if (owner._id === user._id) {
                    userGoals.push(goal);
                }
            }
        }
    }
    return userGoals;
}
