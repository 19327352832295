import React from 'react';
import {Link} from 'react-router-dom';

import {Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Agreement, Keyed} from 'types';
import {statusColor} from 'utils';
import {useAgreements} from 'hooks/useAgreements';


const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {_id}) => {
            return (<Link to={`/agreements/${_id}`}>{text}</Link>);
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
];

const AgreementsListPage = () => {
    const dataSource = (useAgreements() as Keyed<Agreement>[]).map(row => {
        return Object.assign({}, row, {key: row._id});
    })
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
}

export default AgreementsListPage;
