import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

import type {ThemeMode} from 'types';
import {RootState} from 'store';

const themeModeSlice = createSlice({
    name: 'themeMode',
    initialState: {
        value: localStorage.getItem('theme') || 'system'
    },
    reducers: {
        setThemeMode: (state, action: PayloadAction<ThemeMode>) => {
            localStorage.setItem('theme', action.payload);
            state.value = action.payload;
        }
    }
});

export const { setThemeMode } = themeModeSlice.actions;
export const getThemeMode = (state: RootState) => state.themeMode.value;

export default themeModeSlice.reducer;