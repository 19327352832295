import {Goal} from 'types';
import apiClient from './apiClient';

import store from 'store';

import {setGoals} from 'features/goalSlice';

export async function goals() {
    const {goals} = store.getState();
    if (goals.value !== null) {
        return goals.value;
    }

    return apiClient
        .get('/goals')
        .then(res => {
                return res.json() as Promise<Goal[]>
            }
        )
        .then(goals => {
            store.dispatch(setGoals(goals));
            return goals;
        });
}
