import React from 'react';

import {UIMatch} from 'react-router-dom';
import {Outlet, useMatches} from 'react-router-dom';

import type {ItemType} from 'antd/es/breadcrumb/Breadcrumb';
import {Layout} from 'antd';

import {trimSlashes} from 'utils';
import MainSider from './sider/MainSider';
import MainHeader from './header/MainHeader';

const {Content, Footer} = Layout;

type RouteHandle = {
    title?: string;
    breadcrumbs?: (data: any) => ItemType[]
};

function title(matches: UIMatch[]): string | undefined {
    const lastRoute = matches[matches.length - 1];
    return trimSlashes((lastRoute.handle as RouteHandle|undefined)?.title);
}

function breadCrumbItems(matches: UIMatch[]) {
    const lastRoute = matches[matches.length - 1];
    const breadcrumbs = (lastRoute.handle as RouteHandle|undefined)?.breadcrumbs;
    return breadcrumbs ? breadcrumbs(lastRoute.data) : undefined;
}

const MainPage = () => {
    const matches = useMatches();
    const breadcrumbs = breadCrumbItems(matches);
    const pageTitle = title(matches);

    return (
        <Layout style={{minHeight: "100vh"}}>
            <MainSider />
            <Layout style={{padding: '0 24px 24px'}}>
                <MainHeader pageTitle={pageTitle} breadcrumbs={breadcrumbs} />
                <Content>
                    <Outlet/>
                </Content>
                <Footer style={{textAlign: 'center'}}>
                    Proprietary & Confidential
                </Footer>
            </Layout>
        </Layout>
    );
}

export default MainPage;
