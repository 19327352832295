import React from 'react';
import {Link} from 'react-router-dom';

import {Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Keyed, ObjectType, Template} from 'types';
import {useTemplates} from '../../hooks/useTemplates';

const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {_id, name}) => {
            return (<Link to={`/templates/${_id}`}>{text}</Link>);
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    }
];

const TemplatesListPage = (props: {
    objectType: ObjectType
}) => {
    const dataSource = (useTemplates() as Keyed<Template>[])
        .filter(template => template._objectType === props.objectType)
        .map(row => {
            return Object.assign({}, row, {key: row._id});
        })
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
}

export default TemplatesListPage;
