import {RoleAssignment} from 'types';
import apiClient from './apiClient';

import store from 'store';

import {setRoleAssignments} from '../features/roleAssignmentSlice';

export async function roleAssignments() {
    const {roleAssignments} = store.getState();
    if (roleAssignments.value !== null) {
        return roleAssignments.value;
    }

    return apiClient
        .get('/role-assignments')
        .then(res => {
                return res.json() as Promise<RoleAssignment[]>
            }
        )
        .then(roleAssignments => {
            store.dispatch(setRoleAssignments(roleAssignments));
            return roleAssignments;
        });
}
