/* This is the main page loader. */
import {currentUser} from 'services/users';
import {templates} from 'services/templates';
import {agreements} from 'services/agreements';
import {goals} from 'services/goals';

export async function loader() {
    return Promise.all([currentUser(), templates(), goals(), agreements()])
        .then((values) => {
            return {
                currentUser: values[0],
                templates: values[1],
                goals: values[2],
                agreements: values[3]
            }
        })
}
