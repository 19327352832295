// https://gist.github.com/awareness481/b8a0cd4e6d4b6a3f02f3aa9f3cc47e56
import { useSyncExternalStore } from 'react';

export const useColorScheme = () => {
    return useSyncExternalStore(
        subscribe,
        getSnapshot,
        getServerSnapshot
    );
};

function getServerSnapshot() {
    return 'no-preference';
}

function getSnapshot() {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
    }
    if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        return 'light';
    }

    return 'no-preference';
}

function subscribe(callback: (callback: MediaQueryListEvent) => unknown) {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    const isLight = window.matchMedia('(prefers-color-scheme: light)');

    isDark.addEventListener('change', callback);
    isLight.addEventListener('change', callback);
    return () => {
        isDark.removeEventListener('change', callback);
        isLight.removeEventListener('change', callback);
    };
}