import {useNavigate} from 'react-router-dom';
import {Button, Flex, Modal, theme} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import {userIsAdmin} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {agreements, deleteAgreement} from 'services/agreements';

const DeleteAgreementButton = (props: {
    agreementId: string
}) => {
    const isAdmin = userIsAdmin(useCurrentUser());
    const {token: {colorError}} = theme.useToken();
    const navigate = useNavigate();

    if (!isAdmin) {
        return null;
    }

    async function onConfirm() {
        await deleteAgreement(props.agreementId);
        await agreements(false);
        navigate('/', {replace:true});
    }

    return (
        <Flex>
            <Button
                danger
                onClick={() => {
                    Modal.confirm({
                        title: 'Confirm Agreement Deletion',
                        content: 'Are you sure you want to permanently delete this agreement?  This operation cannot be undone.',
                        footer: (_, {OkBtn, CancelBtn}) => (
                            <>
                                <CancelBtn/>
                                <OkBtn />
                            </>
                        ),
                        okText: 'Delete Agreement',
                        okButtonProps: {type:'default', danger:true},
                        icon: <CloseCircleOutlined style={{color:colorError}}/>,
                        onOk: onConfirm
                    });
                }}
            >
                Delete Agreement
            </Button>
        </Flex>
    )
}

export default DeleteAgreementButton;
