import React from 'react';
import {Typography, Flex} from 'antd';

const AuthPage = () => {
    return (
        <Flex align={'center'} justify={'center'} vertical>
            <Typography.Title level={2}>Authentication Needed!</Typography.Title>
            <Typography.Text>See the instructions on Github.</Typography.Text>
        </Flex>
    );
}

export default AuthPage;
