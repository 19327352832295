import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {RoleAssignment} from 'types';
import {RootState} from 'store';

const roleAssignmentSlice = createSlice({
    name: 'roleAssignments',
    initialState: {
        value: null as RoleAssignment[] | null,
    },
    reducers: {
        setRoleAssignments: (state, action: PayloadAction<RoleAssignment[]|null>) => {
            state.value = action.payload;
        }
    }
});

export const { setRoleAssignments } = roleAssignmentSlice.actions;
export const getRoleAssignments = (state: RootState) => state.roleAssignments.value;

export default roleAssignmentSlice.reducer;
