import React from 'react';
import {useLoaderData} from 'react-router-dom';

import type {CustomFieldValue, User} from 'types';
import OrgChartComponent, {UserDatum} from './OrgChartComponent';

const REPORTS_TO_NAME = 'Primary Report To';
const TITLE_NAME = 'Title';

type ObjectContent = {objectId: string};
type TextContent = {text: string};


function mapUsers(users: User[]) {
    const result: Record<string, User> = {}
    for (const user of users) {
        result[user._id] = user;
    }
    return result;
}

function getTitle(userId: string, users: Record<string, User>, customFieldValuesMapping: Record<string, Record<string, CustomFieldValue>>): string {
    const user = users[userId];
    const fields = customFieldValuesMapping[user._id];

    if (user) {
        const field = fields[TITLE_NAME];
        if (field) {
            return (field.content as TextContent).text;
        }
    }
    return '';
}


function buildData(users: User[], customFieldValuesMapping: Record<string, Record<string, CustomFieldValue>>) {
    const userMapping = mapUsers(users);

    const result: Record<string, UserDatum> = {};
    for (const user of users) {
        const fields = customFieldValuesMapping[user._id];
        if (fields[REPORTS_TO_NAME]) {
            const reportsToUser = userMapping[(fields[REPORTS_TO_NAME].content as ObjectContent[])[0].objectId];

            result[user._id] = {
                id: user._id,
                parentId: reportsToUser._id,
                name: user.name,
                title: getTitle(user._id, userMapping, customFieldValuesMapping),
                imageUrl: user._picture_medium
            };

            if (!result[reportsToUser._id]) {
                // This may be overwritten if found later...
                result[reportsToUser._id] = {
                    id: reportsToUser._id,
                    parentId: "",
                    name: reportsToUser.name,
                    title: getTitle(reportsToUser._id, userMapping, customFieldValuesMapping),
                    imageUrl: reportsToUser._picture_medium
                };
            }
        }
    }
    return Object.values(result);
}

const OrganizationPage = () => {
    const {users, customFieldValuesMapping} = useLoaderData() as { users: User[], customFieldValuesMapping: Record<string, Record<string, CustomFieldValue>> };

    return (
        <OrgChartComponent data={buildData(users, customFieldValuesMapping)} />
    );
}

export default OrganizationPage;
