import {CustomFieldValue} from 'types';
import apiClient from './apiClient';

export async function customFieldValues(args: {
    searchParams?: {
        objectType: CustomFieldValue['_objectType'],
        objectId: CustomFieldValue['_objectId'],
    }
}) {
    let url = '/custom-field-values';
    if (args.searchParams?.objectType) {
        url += `?objectType=${args.searchParams.objectType}&objectId=${args.searchParams.objectId}`;
    }

    return apiClient
        .get(url)
        .then(res => {
                return res.json() as Promise<CustomFieldValue[]>
            }
        );
}
