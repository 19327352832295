import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {Team} from 'types';
import {RootState} from 'store';

const teamSlice = createSlice({
    name: 'teams',
    initialState: {
        value: null as Team[] | null,
    },
    reducers: {
        setTeams: (state, action: PayloadAction<Team[]|null>) => {
            state.value = action.payload;
        }
    }
});

export const { setTeams } = teamSlice.actions;
export const getTeams = (state: RootState) => state.teams.value;

export default teamSlice.reducer;
