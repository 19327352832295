import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {Goal} from 'types';
import {RootState} from 'store';

const goalSlice = createSlice({
    name: 'goals',
    initialState: {
        value: null as Goal[] | null,
    },
    reducers: {
        setGoals: (state, action: PayloadAction<Goal[]|null>) => {
            state.value = action.payload;
        }
    }
});

export const { setGoals } = goalSlice.actions;
export const getGoals = (state: RootState) => state.goals.value;

export default goalSlice.reducer;
