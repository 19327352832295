import {User} from 'types';
import apiClient, {SOBOL_URL} from './apiClient';

import store from 'store';

import {setCurrentUser} from 'features/currentUserSlice';

export async function users() {
    return apiClient
        .get('/users')
        .then(res => {
                return res.json() as Promise<User[]>
            }
        );
}

export async function user(userId: string) {
    return apiClient
        .get(`/users/${userId}`)
        .then(res => {
                return res.json() as Promise<User>
            }
        );
}

type CurrentUser = {
    localUsers: User[]
}

/*
 * The current endpoint returns a different kind of user (and is undocumented).
 * The true 'User' object is in localUsers.
 */
export async function currentUser() {
    const url = `${SOBOL_URL}/users/current`;
    return apiClient
        .get(url)
        .then(res => {
            return res.json() as Promise<CurrentUser>
        })
        .then(currentUser => {
            if (currentUser.localUsers.length > 0) {
                store.dispatch(setCurrentUser(currentUser.localUsers[0]));
                return user;
            }
            return null;
        });
}
