import {redirect} from 'react-router-dom';

export const SOBOL_URL = 'https://proxy.viggoteam24.workers.dev';

export function getToken() {
    return window.localStorage.getItem('sobol_jwt');
}


// SOBOL_URL: https://sobol.io/d/api/v1
function buildUrl(baseUrl: string) {
    return baseUrl.startsWith('/')
        ? `${SOBOL_URL}/org/${process.env.REACT_APP_SOBOL_ORG_ID}${baseUrl}`
        : baseUrl;
}

async function throwError(res: Response) {
    let error = res;
    try {
        error = await res.json();
    } catch (e)  {}
    throw error;
}

async function get(url: string): Promise<Response> {
    const token = getToken();
    if (!token) {
        return new Promise((_, reject) => reject(redirect('/auth')));
    }

    return fetch(
        buildUrl(url),
        {
            method: 'GET',
            headers: {
                'X-Origin': window.location.origin,
                Authorization: `Bearer ${token}`
            },
        }
    ).then(res => {
        if (res.status !== 200) {
            if (res.status === 401) {
                return new Promise((_, reject) => reject(redirect('/auth')));
            }
            throwError(res);
        }
        return res;
    });
}

async function doDelete(url: string): Promise<Response> {
    const token = getToken();
    if (!token) {
        return new Promise((_, reject) => reject(redirect('/auth')));
    }

    return fetch(
        buildUrl(url),
        {
            method: 'DELETE',
            headers: {
                'X-Origin': window.location.origin,
                Authorization: `Bearer ${token}`
            },
        }
    ).then(res => {
        if (res.status !== 200) {
            if (res.status === 401) {
                return new Promise((_, reject) => reject(redirect('/auth')));
            }
            throwError(res);
        }
        return res;
    });
}

async function request(method: string, url: string, data: any): Promise<Response> {
    const token = getToken();
    if (!token) {
        return new Promise((_, reject) => reject(new Response('Unauthorized', {status: 403})));
    }

    return fetch(
        buildUrl(url),
        {
            method: method,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Origin': window.location.origin,
                Authorization: `Bearer ${token}`
            },
        }
    ).then(res => {
        if (res.status >= 300) {
            throwError(res);
        }
        return res;
    });
}

async function post(url: string, data: any): Promise<Response> {
    return request('POST', url, data);
}

async function patch(url: string, data: any): Promise<Response> {
    return request('PATCH', url, data);
}

const apiClient = {
    get,
    patch,
    post,
    delete: doDelete
}

export default apiClient;
