import { configureStore } from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';

import themeModeSlice from 'features/themeModeSlice';
import currentUserSlice from 'features/currentUserSlice';
import roleSlice from 'features/roleSlice';
import roleAssignmentSlice from 'features/roleAssignmentSlice';
import teamSlice from 'features/teamSlice';
import templateSlice from 'features/templateSlice';
import agreementSlice from 'features/agreementSlice';
import goalSlice from 'features/goalSlice';

const store = configureStore({
    reducer: {
        themeMode: themeModeSlice,
        currentUser: currentUserSlice,

        agreements: agreementSlice,
        goals: goalSlice,
        roles: roleSlice,
        roleAssignments: roleAssignmentSlice,
        teams: teamSlice,
        templates: templateSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export default store;
