import React from 'react';
import {Link, useLoaderData} from 'react-router-dom';

import {Flex, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Keyed, Team} from 'types';


function statusColor(status: string) {
    if (status === 'archived') {
        return 'volcano';
    }
    if (status === 'draft') {
        return 'geekblue';
    }
    return 'green';
}

const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {_id, name, _picture_small}) => {
            if (_picture_small) {
                return (
                    <Flex align={'center'} gap={'1rem'}>
                        <img alt={name} src={_picture_small} />
                        <Link to={`/teams/${_id}`}>{text}</Link>
                    </Flex>
                )
            }
            return (<Link to={`/teams/${_id}`}>{text}</Link>);
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
];

const TeamsListPage = () => {
    const dataSource = (useLoaderData() as Keyed<Team>[]).map(row => {
        return Object.assign({}, row, {key: row._id});
    })
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
}

export default TeamsListPage;
