import {getThemeMode} from 'features/themeModeSlice';
import {useAppSelector} from 'store';

import {useColorScheme} from './useColorScheme';

function lightOrDark(themeMode: string, colorScheme: string): 'light'|'dark' {
    if (themeMode === 'system') {
        return colorScheme === 'dark' ? 'dark' : 'light';
    }
    return themeMode as 'light' | 'dark';
}

export function useThemeMode() {
    const colorScheme = useColorScheme();
    const themeMode = useAppSelector(getThemeMode);
    return lightOrDark(themeMode, colorScheme)
}
