import React from 'react';
import {Card, Flex, Typography} from 'antd';

import {useCurrentUser} from 'hooks/useCurrentUser';
import UserGoalsTable from 'components/UserGoalsTable';
import UserAgreementsTable from 'components/UserAgreementsTable';

const DashboardPage = () => {
    const currentUser = useCurrentUser();
    if (!currentUser) {
        return null;
    }
    return (
        <Flex vertical gap={'8px'}>
            <Card>
                <Typography.Title level={2}>My Goals</Typography.Title>
                <UserGoalsTable user={currentUser}/>
            </Card>

            <Card>
                <Typography.Title level={2}>My Agreements</Typography.Title>
                <UserAgreementsTable user={currentUser}/>
            </Card>
        </Flex>
    );
}

export default DashboardPage;
