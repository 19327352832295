import {useCallback} from 'react';
import {network} from './network';

export type Graph = {
    name: string;
    type: 'team'|'role';
    value?: number;
    children: Graph[];
}

const NetworkComponent = (props: { data: Graph }) => {
        const handleRef = useCallback((element: HTMLDivElement) => {
            if (element) {
                const resizeObserver = new ResizeObserver(() => {
                    window.requestAnimationFrame((): void | undefined => {
                        if (element.offsetWidth && element.offsetHeight) {
                            network(element, props.data);
                        }
                    });
                });
                resizeObserver.observe(element);
            }
        }, [props.data]);
        return (
            <div style={{width: '100%', height: '100%'}} ref={handleRef}/>
        );
    }
;

export default NetworkComponent;
