import {useAppSelector} from 'store';

import type {Template} from 'types';
import {getTemplates} from 'features/templateSlice';

export function useTemplates(): Template[] {
    return (useAppSelector(getTemplates) || []);
}

export function useTemplate(id: string|undefined): Template|undefined {
    const templates = useTemplates();
    return id ? templates.find((template) => template._id === id) : undefined;
}
