import {User} from './types';

export function isString(s: any) {
    return (typeof s === 'string' || s instanceof String);
}

export function initials(name: string) {
    const words = name.split(' ');
    if (words.length >= 2) {
        return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`;
    }
    if (words.length === 1) {
        return `${words[0][0].toUpperCase()}`;
    }
    return '?'
}

export function trimSlashes(value: string|undefined) {
    return value !== undefined ? value.replace(/^\/|\/$/g, '') : undefined;
}

// https://stackoverflow.com/a/38552302
export function parseJwt (token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function userTypeDisplay(userType: User['_userType']) {
    switch (userType) {
        case 'read_only': return 'Viewer';
        case 'admin': return 'Administrator';
        case 'regular': return 'Editor';
    }
    return userType.toString().toUpperCase();
}

export function map<T>(objects: T[]) {
    const map = {} as Record<string, T>;
    for (const object of objects) {
        map[(object as {_id: string})._id] = object;
    }
    return map;
}

export function statusColor(status: string) {
    if (status === 'archived') {
        return 'volcano';
    }
    if (status === 'draft') {
        return 'geekblue';
    }
    if (status === 'active') {
        return 'cyan';
    }
    return 'green';
}

export function userIsAdmin(user: User|null|undefined) {
    return user?._userType === 'admin';
}