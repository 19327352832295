import React from 'react';
import {Link} from 'react-router-dom';

import {Flex, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Goal, Keyed} from 'types';
import {statusColor} from 'utils';
import {useGoals} from 'hooks/useGoals';


const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {_id, name, _picture_small}) => {
            if (_picture_small) {
                return (
                    <Flex align={'center'} gap={'1rem'}>
                        <img alt={name} src={_picture_small} />
                        <Link to={`/goals/${_id}`}>{text}</Link>
                    </Flex>
                )
            }
            return (<Link to={`/goals/${_id}`}>{text}</Link>);
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
];

const GoalsListPage = () => {
    const dataSource = (useGoals() as Keyed<Goal>[]).map(row => {
        return Object.assign({}, row, {key: row._id});
    })
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
}

export default GoalsListPage;
