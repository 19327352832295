import {Agreement} from 'types';
import apiClient from './apiClient';

import store from 'store';

import {setAgreements} from 'features/agreementSlice';

export async function agreements(useCache: boolean = true) {
    const {agreements} = store.getState();
    if (useCache && agreements.value !== null) {
        return agreements.value;
    }

    return apiClient
        .get('/agreements')
        .then(res => {
                return res.json() as Promise<Agreement[]>
            }
        )
        .then(agreements => {
            store.dispatch(setAgreements(agreements));
            return agreements;
        });
}

export async function deleteAgreement(agreementId: string) {
    return apiClient.delete(`/agreements/${agreementId}`)
}