import React from 'react';
import {Flex} from 'antd';

const MainContent = (props: {
    children: React.ReactNode,
}) => {
    return (
        <Flex vertical gap={'8px'}>
            {props.children}
        </Flex>
    );
}

export default MainContent;
