import {users} from 'services/users';
import {roles} from 'services/roles';

export async function usersListLoader() {
    return Promise.all([users(), roles()])
        .then((values) => {
            return {
                users: values[0],
                roles: values[1]
            }
        })
}
