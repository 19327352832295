import {User} from 'types';

import {useAppSelector} from 'store';

import {getRoleAssignments} from 'features/roleAssignmentSlice';
import {getRoles} from 'features/roleSlice';

export function useUserRoles(user: User) {
    const roleAssignmentIds = (useAppSelector(getRoleAssignments) || [])
        .filter(roleAssignment => {
            return user._id === roleAssignment.assignee._id;
        })
        .map((roleAssignment) => {
            return roleAssignment.role._id;
        });

    return (useAppSelector(getRoles) || [])
        .filter((role) => {
            return roleAssignmentIds.includes(role._id);
        })
}
