import showdown from 'showdown';
import {Card} from 'antd';

const MarkdownCard = (props: {
    markdown: string;
}) => {
    const converter = new showdown.Converter();
    return (
        <Card>
            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(props.markdown) }} />
        </Card>
    );
}

export default MarkdownCard;
