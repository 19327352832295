import React from 'react';
import {useLoaderData} from 'react-router-dom';

import {Card, Descriptions, Flex} from 'antd';

import type {Team} from 'types';
import Object from 'components/Object';
import MainContent from 'components/MainContent';


function items(team: Team) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: team.name
        },
        {
            key: 'description',
            label: 'Description',
            children: team.description
        },
        {
            key: 'templateId',
            label: 'Template',
            children: team.templateId
        },
        {
            key: 'status',
            label: 'Status',
            children: team.status
        }
    ]
}


const TeamDetailPage = () => {
    const data = useLoaderData() as Team;
    return (
        <MainContent>
            <Card>
                {data._picture_medium ?
                    <Flex justify={'center'}>
                        <img src={data._picture_medium} alt={data.name}/>
                    </Flex> : null
                }
                <Descriptions
                    title="Team Info"
                    items={items(data)}
                />
                <Object object={data}/>
            </Card>
            <Object object={data}/>
        </MainContent>
    );
}

export default TeamDetailPage;
