import type {Params} from 'react-router-dom';

import {Team} from 'types';
import apiClient from './apiClient';

import store from 'store';

import {setTeams} from 'features/teamSlice';

export async function teams() {
    const {teams} = store.getState();
    if (teams.value !== null) {
        return teams.value;
    }

    return apiClient
        .get('/teams')
        .then(res => {
                return res.json() as Promise<Team[]>
            }
        )
        .then(teams => {
            store.dispatch(setTeams(teams));
            return teams;
        });
}


export async function team(args: { params: Params<'teamId'> }) {
    return apiClient
        .get(`/teams/${args.params.teamId}`)
        .then(res => {
                return res.json() as Promise<Team>
            }
        );
}
