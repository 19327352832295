import {Flex, Typography} from 'antd';
import {useCurrentUser} from 'hooks/useCurrentUser';
import UserImage from './UserImage';


const HeaderUser = () => {
    const currentUser = useCurrentUser();

    if (!currentUser) {
        return null;
    }

    return (
        <Flex align={'center'} gap={'8px'}>
            <UserImage user={currentUser} />
            <Typography.Text>{currentUser.name}</Typography.Text>
        </Flex>
    );
}

export default HeaderUser;
