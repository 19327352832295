import React, {useCallback} from 'react';

import {OrgChart} from 'd3-org-chart';
import type {HierarchyNode} from 'd3-hierarchy';
import {Card} from 'antd';

import styles from './OrgChartComponent.module.scss'
import {initials} from '../../utils';

export type UserDatum = {
    id: string,
    parentId: string|null
    name: string,
    title: string|null,
    imageUrl?: string | null,
}

export type UserNode = HierarchyNode<UserDatum> & {readonly width: number};


function nodeContent(d: HierarchyNode<UserDatum>) {
    const node = d as UserNode;
    const imageUrl = node.data.imageUrl;


    if (!imageUrl) {
        return `
            <div class="circle">${initials(node.data.name)}</div>
            <div class="name">${node.data.name}</div>
            <div class="title">${node.data.title}</div>
        `;
    }

    return `
        <div class="img">
            <img src="${imageUrl}" alt="${node.data.name}" />
        </div>
        <div class="name">${node.data.name}</div>
        <div class="title">${node.data.title}</div>
    `;
}

const OrgChartComponent = (props: { data: UserDatum[] }) => {

    const handleRef = useCallback((node: HTMLDivElement) => {
        new OrgChart<UserDatum>()
            .container('.org-chart')
            .data(props.data)
            .nodeWidth((d) => 200)
            .nodeHeight((d) => 120)
            .nodeContent(nodeContent)
            .render();
    }, [props.data]);

    return (
        <Card>
            <div className={'org-chart ' + styles.chart} ref={handleRef}/>
        </Card>
    );
};

export default OrgChartComponent;
