import {Template} from 'types';
import apiClient from './apiClient';

import store from 'store';

import {setTemplates} from 'features/templateSlice';

export async function templates() {
    const {templates} = store.getState();
    if (templates.value !== null) {
        return templates.value;
    }

    return apiClient
        .get('/object-templates')
        .then(res => {
                return res.json() as Promise<Template[]>
            }
        )
        .then(templates => {
            store.dispatch(setTemplates(templates));
            return templates;
        });
}
