import {users} from './users';
import {customFieldValues} from './customFieldValues';
import {CustomFieldValue} from '../types';

function toNameMapping(values: CustomFieldValue[]) {
    const mapping: Record<string, CustomFieldValue> = {};
    for (const value of values) {
        mapping[value._customFieldName] = value;
    }
    return mapping;
}

export async function org() {
    const allUsers = await users();

    const promises = allUsers.map(user => {
        return customFieldValues({searchParams: {objectType: 'user', objectId: user._id}})
    });

    const results = await Promise.all(promises);

    const customFieldValuesMapping: Record<string, Record<string, CustomFieldValue>> = {};
    results.forEach((values, index) => {
        customFieldValuesMapping[allUsers[index]._id] = toNameMapping(values);
    })

    return {
        users: allUsers,
        customFieldValuesMapping: customFieldValuesMapping,
    }
}
