import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {Role} from 'types';
import {RootState} from 'store';

const roleSlice = createSlice({
    name: 'roles',
    initialState: {
        value: null as Role[] | null,
    },
    reducers: {
        setRoles: (state, action: PayloadAction<Role[]|null>) => {
            state.value = action.payload;
        }
    }
});

export const { setRoles } = roleSlice.actions;
export const getRoles = (state: RootState) => state.roles.value;

export default roleSlice.reducer;
