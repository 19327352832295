import {useAppSelector} from 'store';

import {Agreement, User} from 'types';
import {getAgreements} from 'features/agreementSlice';

export function useAgreements(): Agreement[] {
    return (useAppSelector(getAgreements) || []);
}

export function useUserAgreements(user: User | null): Agreement[] {
    const agreements = useAgreements();

    const userAgreements = []
    if (user) {
        for (const agreement of agreements) {
            for (const owner of agreement.owners) {
                if (owner._id === user._id) {
                    userAgreements.push(agreement);
                }
            }
        }
    }
    return userAgreements;
}
