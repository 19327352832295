import React from 'react';
import {useLoaderData} from 'react-router-dom';

import {Card, Descriptions, Tag} from 'antd';

import type {Goal, Template} from 'types';
import {map, statusColor} from 'utils';
import {useTemplates} from 'hooks/useTemplates';
import Object from 'components/Object';
import MainContent from 'components/MainContent';


function items(goal: Goal, templates: Record<string, Template>) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: goal.name
        },
        {
            key: 'description',
            label: 'Description',
            children: goal.description
        },
        {
            key: 'template',
            label: 'Template',
            children: goal.templateId,
            render: ({_id}: { _id: string }) => {
                return templates[_id]?.name;
            }
        },
        {
            key: 'status',
            label: 'Status',
            children: goal.status,
            render: (text: string) => (
                <Tag color={statusColor(text)} key={'status'}>
                    {text.toUpperCase()}
                </Tag>
            )
        }
    ]
}


const GoalDetailPage = () => {
    const data = useLoaderData() as Goal;
    const templates = map<Template>(useTemplates());
    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Goal Info"
                    items={items(data, templates)}
                />
            </Card>
            <Object object={data} />
        </MainContent>
    );
}

export default GoalDetailPage;
