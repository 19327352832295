import * as d3 from 'd3';
import {BaseType, HierarchyCircularNode, Selection} from 'd3';

type Graph = {
    name: string;
    type: 'team'|'role'
    value?: number;
    children?: Graph[];
}

type View = [number, number, number];

class Network {
    readonly element: HTMLDivElement;
    readonly width: number;
    readonly height: number;

    readonly data: Graph;

    nodes: Selection<BaseType | SVGCircleElement, HierarchyCircularNode<Graph>, SVGGElement, undefined>;
    labels: Selection<BaseType | SVGTextElement, HierarchyCircularNode<Graph>, SVGGElement, undefined>;

    svg: Selection<SVGSVGElement, undefined, null, undefined>;
    focus: HierarchyCircularNode<Graph>;
    view: View;

    constructor(element: HTMLDivElement, data: Graph) {
        this.element = element;
        this.width = element.offsetWidth;
        this.height = element.offsetHeight;

        this.data = data;

        /*
        const color = d3
            .scaleLinear([0, 5], ['hsl(152,80%,80%)', 'hsl(228,30%,40%)'])
            .interpolate(d3.interpolateHcl);
         */

        const hierarchy = d3.hierarchy<Graph>(data)
            .sum(d => {
                return d.value || 0
            })
            .sort((a, b) => {
                return (b.value || 0) - (a.value || 0);
            });

        // Compute the layout.
        const pack = (_: Graph) => d3.pack<Graph>()
            .size([this.width, this.height])
            .padding(8)
            (hierarchy);

        const root = pack(data);

        this.svg = d3.create('svg')
            .attr('viewBox', `-${this.width / 2} -${this.height / 2} ${this.width} ${this.height}`)
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('style', `max-width: 100%; height: auto; display: block; margin: 0 -14px; cursor: pointer;`);

        const filter = this.svg.append('defs')
            .append('filter')
            .attr('x', '0')
            .attr('y', '0')
            .attr('width', '1')
            .attr('height', '1')
            .attr('id', 'solid')

        filter.append('feFlood')
            .attr('flood-color', 'white');
        filter.append('feComposite')
            .attr('in', 'SourceGraphic');

        this.nodes = this.svg.append('g')
            .selectAll('circle')
            .data(root.descendants().slice(1))
            .join('circle')
            .attr('stroke', d => '#63bab0')
            .attr('fill', d => 'white')
            .attr('pointer-events', d => !d.children ? 'none' : null)
            .on('mouseover', function() {
                d3.select(this).attr('stroke', '#000');
            })
            .on('mouseout', function() {
                d3.select(this).attr('stroke', '#63bab0');
            })
            .on('click', (event, d) => {
                if (this.focus !== d) {
                    event.stopPropagation();
                    this.zoom(event, d)
                }
            });

        // Append the text labels.
        this.labels = this.svg.append('g')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .selectAll('text')
            .data(root.descendants())
            .join('text')
            .attr('filter', d => {
                return d.data.type === 'team' ? 'url(#solid)' : 'none'
            })
            .style('font-family', (d) => {
                return (d.data.type === 'team') ? 'sans-serif' : "'Material Icons'";
            })
            .style('fill-opacity', d => {
                return (d.parent === root || d.data.type === 'role') ? 1 : 0
            })
            .style('display', d => {
                return (d.parent === root || d.data.type === 'role') ? 'inline' : 'none'
            })
            .text(d => {
                return (d.data.type === 'team') ? d.data.name : '\ue853'
            });

        this.svg.on('click', (event) => this.zoom(event, root));

        this.focus = root;
        this.view = [this.focus.x, this.focus.y, this.focus.r * 2];
        this.zoomTo(this.view);

        const svgNode = this.svg.node();
        if (svgNode) {
            this.element.replaceChildren(svgNode);
        }
    }

    zoomTo(view: View) {
        const k = Math.min(this.width, this.height) / view[2];

        this.view = view;

        this.labels
            .attr('transform', d => {
                let y = (d.y - view[1]) * k;
                if (d.data.type === 'role') {
                    y += d.r * k;
                } else if (d.data.type === 'team') {
                    y -= d.r * k + 4;
                }
                return `translate(${(d.x - view[0]) * k},${y})`
            })
            .style('font-size', d => {
                return d.data.type === 'team' ? '16px' : `${((d.r * k) * 2).toString()}px`;
            })

        this.nodes.attr('transform', d => `translate(${(d.x - view[0]) * k},${(d.y - view[1]) * k})`);
        this.nodes.attr('r', d => d.r * k);
    }

    zoom(event: any, d: HierarchyCircularNode<Graph>) {
        const focus = this.focus = d;

        const transition = this.svg.transition()
            .duration(event.altKey ? 7500 : 750)
            .tween('zoom', _ => {
                const i = d3.interpolateZoom(this.view, [this.focus.x, this.focus.y, this.focus.r * 2]);
                return t => this.zoomTo(i(t));
            });

        this.labels
            .filter(function (d) {
                return d.parent === focus || (this as HTMLElement).style.display === 'inline' || d.data.type === 'role';
            })
            .transition(transition as any)
                .style('fill-opacity', d => {
                    return (d.parent === focus || d.data.type === 'role')  ? 1 : 0
                })
                .on('start', function (d) {
                    if (d.parent === focus && d.data.type === 'team'){
                        (this as HTMLElement).style.display = 'inline';
                    }
                })
                .on('end', function (d) {
                    if (d.parent !== focus && d.data.type === 'team') {
                        (this as HTMLElement).style.display = 'none';
                    }
                });
    }
}

export function network(element: HTMLDivElement, data: Graph) {
    return new Network(element, data);
}
