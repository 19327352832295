import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {User} from 'types';
import {RootState} from 'store';

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: {
        value: null as User | null,
    },
    reducers: {
        setCurrentUser: (state, action: PayloadAction<User|null>) => {
            state.value = action.payload;
        }
    }
});

export const { setCurrentUser } = currentUserSlice.actions;
export const getCurrentUser = (state: RootState) => state.currentUser.value;

export default currentUserSlice.reducer;
