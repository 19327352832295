import React, {useEffect, useState} from 'react';
import {createBrowserRouter, RouterProvider, Navigate, Link, RouteObject} from 'react-router-dom';
import {ConfigProvider, theme} from 'antd';

import {Agreement, Team, Template} from 'types';
import {useThemeMode} from 'hooks/useThemeMode';

import ErrorBoundary from 'components/ErrorBoundary';
import ErrorDisplay from 'components/ErrorDisplay';
import MainPage from 'components/MainPage';
import Loading from 'components/Loading';

import {teams, team} from 'services/teams';
import AuthPage from 'pages/AuthPage';
import DashboardPage from 'pages/dashboard/DashboardPage';

import TeamsListPage from 'pages/teams/TeamsListPage';
import TeamDetailPage from 'pages/teams/TeamDetailPage';

import OrganizationPage from 'pages/org/OrganizationPage';
import NetworkPage from 'pages/network/NetworkPage';
import {networkPageLoader} from 'pages/network/networkPageLoader';

import UsersListPage from 'pages/users/UsersListPage';
import {usersListLoader} from 'pages/users/usersListLoader';
import UserDetailPage from 'pages/users/UserDetailPage';
import {userDetailLoader} from 'pages/users/userDetailLoader';

import TemplatesListPage from 'pages/templates/TemplatesListPage';
import TemplateDetailPage from 'pages/templates/TemplateDetailPage';
import {templateDetailLoader} from 'pages/templates/templateDetailLoader';

import GoalsListPage from 'pages/goals/GoalsListPage';
import GoalDetailPage from 'pages/goals/GoalDetailPage';
import {goalDetailLoader} from 'pages/goals/goalDetailLoader';

import {loader} from 'loader';
import {org} from 'services/org';
import {goals} from 'services/goals';
import AgreementsListPage from './pages/agreements/AgreementsListPage';
import {agreements} from './services/agreements';
import AgreementDetailPage from './pages/agreements/AgreementDetailPage';
import {agreementDetailLoader} from './pages/agreements/agreementDetailLoader';


function createRouter() {
    return createBrowserRouter([
        {
            path: '/auth',
            element: <AuthPage/>,
        },
        {
            path: '',
            element: <MainPage/>,
            errorElement: <ErrorBoundary/>,
            children: [
                {
                    path: '/',
                    element: <DashboardPage/>,
                    handle: {
                        title: 'My Dashboard',
                    },
                },
                {
                    path: '/org',
                    element: <OrganizationPage/>,
                    loader: org,
                    handle: {
                        title: 'Organization',
                    },
                },
                {
                    path: '/network',
                    element: <NetworkPage/>,
                    loader: networkPageLoader,
                    handle: {
                        title: 'Network',
                    },
                },
                {
                    path: '/teams',
                    children: [
                        {
                            path: '',
                            element: <TeamsListPage/>,
                            loader: teams,
                            handle: {
                                title: 'Teams',
                            },
                        },
                        {
                            path: ':teamId',
                            element: <TeamDetailPage/>,
                            loader: team,
                            handle: {
                                breadcrumbs: (data: Team) => [
                                    {
                                        title: <Link to={'/teams'}>Teams</Link>,
                                    },
                                    {
                                        title: data.name,
                                    }
                                ]
                            },
                        }
                    ]
                },
                {
                    path: '/goals',
                    children: [
                        {
                            path: '',
                            element: <GoalsListPage/>,
                            loader: goals,
                            handle: {
                                title: 'Goals',
                            },
                        },
                        {
                            path: ':goalId',
                            element: <GoalDetailPage/>,
                            loader: goalDetailLoader,
                            handle: {
                                breadcrumbs: (data: Team) => [
                                    {
                                        title: <Link to={'/goals'}>Goals</Link>,
                                    },
                                    {
                                        title: data.name,
                                    }
                                ]
                            },
                        }
                    ]
                },
                {
                    path: '/agreements',
                    children: [
                        {
                            path: '',
                            element: <AgreementsListPage/>,
                            loader: agreements,
                            handle: {
                                title: 'Agreements',
                            },
                        },
                        {
                            path: ':agreementId',
                            element: <AgreementDetailPage/>,
                            loader: agreementDetailLoader,
                            handle: {
                                breadcrumbs: (data: Agreement) => [
                                    {
                                        title: <Link to={'/agreements'}>Agreements</Link>,
                                    },
                                    {
                                        title: data.name,
                                    }
                                ]
                            },
                        }
                    ]
                },
                {
                    path: '/users',
                    children: [
                        {
                            path: '',
                            element: <UsersListPage/>,
                            loader: usersListLoader,
                            handle: {
                                title: 'Users',
                            },
                        },
                        {
                            path: ':userId',
                            element: <UserDetailPage/>,
                            loader: userDetailLoader,
                            handle: {
                                breadcrumbs: (data: Team) => [
                                    {
                                        title: <Link to={'/users'}>Users</Link>,
                                    },
                                    {
                                        title: data.name,
                                    }
                                ]
                            },
                        }
                    ]
                },
                {
                    path: '/templates',
                    children: [
                        {
                            path: 'teams',
                            element: <TemplatesListPage objectType={'team'}/>,
                            handle: {
                                title: 'Team Templates',
                            },
                        },
                        {
                            path: 'users',
                            element: <TemplatesListPage objectType={'user'}/>,
                            handle: {
                                title: 'User Templates',
                            },
                        },
                        {
                            path: 'goals',
                            element: <TemplatesListPage objectType={'goal'}/>,
                            handle: {
                                title: 'Goal Templates',
                            },
                        },
                        {
                            path: 'roles',
                            element: <TemplatesListPage objectType={'role'}/>,
                            handle: {
                                title: 'Team Templates',
                            },
                        },
                        {
                            path: 'role-assignments',
                            element: <TemplatesListPage objectType={'role_assignment'}/>,
                            handle: {
                                title: 'Role Assignment Templates',
                            },
                        },
                        {
                            path: 'agreements',
                            element: <TemplatesListPage objectType={'agreement'}/>,
                            handle: {
                                title: 'Agreement Templates',
                            },
                        },
                        {
                            path: ':templateId',
                            element: <TemplateDetailPage/>,
                            loader: templateDetailLoader,
                            handle: {
                                breadcrumbs: (data: Template) => [
                                    {
                                        title: <Link to={'/templates'}>Templates</Link>,
                                    },
                                    {
                                        title: data?.name,
                                    }
                                ]
                            },
                        }
                    ]
                },
                {
                    path: '*',
                    element: <Navigate to={'/'} replace/>,
                }
            ]
        }
    ] as RouteObject[]);
}

function App() {
    const mode = useThemeMode();
    const [error, setError] = useState('');
    const [ready, setReady] = useState(false);

    useEffect(() => {
        loader()
            .then(() => setReady(true))
            .catch((e) => setError(e))
    });

    return (
        <ConfigProvider wave={{disabled: true}} theme={{
            algorithm: mode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
            token: {
                colorPrimary: '#63bab0',
                colorLink: '#63bab0',
            },
            components: {
                Table: {
                    headerColor: '#8c8c8c',
                    headerBg: 'transparent'
                }
            }
        }}>
            {ready ? <RouterProvider router={createRouter()}/> : null}
            {error ? <ErrorDisplay message={error} /> : null}
            { !ready && !error ? <Loading /> : null}
        </ConfigProvider>
    );
}

export default App;
