import React from 'react';
import {Link, useLoaderData} from 'react-router-dom';

import {Avatar, Flex, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {UserOutlined} from '@ant-design/icons';

import type {Keyed, Role, User} from 'types';
import {statusColor, userTypeDisplay} from 'utils';

const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {_id, name, _picture_small}) => {
            return (
                <Flex align={'center'} gap={'1rem'}>
                    {_picture_small ?
                        <Avatar size={32} src={_picture_small} /> :
                        <Avatar size={32} icon={<UserOutlined/>} />
                    }
                    <Link to={`/users/${_id}`}>{text}</Link>
                </Flex>
            )
        }
    },
    {
        title: 'Type',
        dataIndex: '_userType',
        key: '_userType',
        render: text => userTypeDisplay(text)
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
];

const UsersListPage = () => {
    const loaderData = useLoaderData() as {
        users: Keyed<User>[],
        roles: Role[],
    };

    const dataSource = loaderData.users.map(row => {
        row.key = row._id;
        return row;
    })
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
}

export default UsersListPage;
