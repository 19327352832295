import {Role} from 'types';
import apiClient from './apiClient';

import store from 'store';

import {setRoles} from 'features/roleSlice';

export async function roles() {
    const {roles} = store.getState();
    if (roles.value !== null) {
        return roles.value;
    }

    return apiClient
        .get('/roles')
        .then(res => {
                return res.json() as Promise<Role[]>
            }
        )
        .then(roles => {
            store.dispatch(setRoles(roles));
            return roles;
        });
}
