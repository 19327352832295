import React from 'react';
import {useLoaderData} from 'react-router-dom';

import {Card, Descriptions, Flex, Typography} from 'antd';

import type {User} from 'types';
import UserRolesTable from './UserRolesTable';
import UserGoalsTable from 'components/UserGoalsTable';
import UserAgreementsTable from 'components/UserAgreementsTable';
import MainContent from 'components/MainContent';
import Object from 'components/Object';


function items(user: User) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: user.name,
            span: 4
        },
        {
            key: 'city',
            label: 'City',
            children: user.city,
            span: 4
        },
        {
            key: 'state',
            label: 'State',
            children: user.state,
            span: 2
        },
        {
            key: 'countryCode',
            label: 'Country',
            children: user.countryCode,
            span: 2
        },
        {
            key: 'timezone',
            label: 'Timezone',
            children: user.timezone,
            span: 4
        },
        {
            key: 'status',
            label: 'Status',
            children: user.status,
            span: 4
        }
    ]
}


const UserDetailPage = () => {
    const {user} = useLoaderData() as { user: User };

    return (
        <MainContent>
            <Card>
                {user._picture_medium ?
                    <Flex justify={'center'}>
                        <img src={user._picture_medium} alt={user.name} />
                    </Flex>: null
                }
                <Descriptions
                    title="User Info"
                    items={items(user)}
                    column={4}
                />
            </Card>
            <UserRolesTable user={user} />
            <Card>
                <Typography.Title level={2}>Goals</Typography.Title>
                <UserGoalsTable user={user} />
            </Card>
            <Card>
                <Typography.Title level={2}>Agreements</Typography.Title>
                <UserAgreementsTable user={user} />
            </Card>
            <Object object={user} />
        </MainContent>
    );
}

export default UserDetailPage;
