import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {Template} from 'types';
import {RootState} from 'store';

const templateSlice = createSlice({
    name: 'templates',
    initialState: {
        value: null as Template[] | null,
    },
    reducers: {
        setTemplates: (state, action: PayloadAction<Template[]|null>) => {
            state.value = action.payload;
        }
    }
});

export const { setTemplates } = templateSlice.actions;
export const getTemplates = (state: RootState) => state.templates.value;

export default templateSlice.reducer;
