import {Menu, type MenuProps} from 'antd';
import {Link, UIMatch, useMatches} from 'react-router-dom';
import {
    AimOutlined,
    ApartmentOutlined,
    HomeOutlined,
    LikeOutlined,
    TableOutlined,
    TeamOutlined,
    TrophyOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {trimSlashes} from 'utils';

const items: MenuProps['items'] = [
    {
        key: 'dashboard',
        label: <Link to="/">Dashboard</Link>,
        icon: <HomeOutlined/>
    },
    {
        key: 'org',
        label: <Link to="/org">Organization</Link>,
        icon: <ApartmentOutlined/>
    },
    {
        key: 'network',
        label: <Link to="/network">The Network</Link>,
        icon: <AimOutlined/>
    },
    {
        type: 'divider',
        style: {
            marginTop: '16px',
            marginBottom: '16px',
        }
    },
    {
        key: 'tables',
        label: 'Tables',
        type: 'group',
        children: [
            {
                key: 'teams',
                label: <Link to="/teams">Teams</Link>,
                icon: <TeamOutlined/>
            },
            {
                key: 'goals',
                label: <Link to="/goals">Goals</Link>,
                icon: <TrophyOutlined/>
            },
            {
                key: 'agreements',
                label: <Link to="/agreements">Agreements</Link>,
                icon: <LikeOutlined/>
            },
            {
                key: 'users',
                label: <Link to="/users">Users</Link>,
                icon: <UserOutlined/>
            },
            {
                key: 'templates',
                label: 'Templates',
                icon: <TableOutlined/>,
                children: [
                    {
                        key: 'teams',
                        label: <Link to="/templates/teams">Teams</Link>,
                    },
                    {
                        key: 'users',
                        label: <Link to="/templates/users">Users</Link>,
                    },
                    {
                        key: 'goals',
                        label: <Link to="/templates/goals">Goals</Link>,
                    },
                    {
                        key: 'roles',
                        label: <Link to="/templates/roles">Roles</Link>,
                    },
                    {
                        key: 'role-assignments',
                        label: <Link to="/templates/role-assignments">Role Assignments</Link>,
                    },
                    {
                        key: 'agreements',
                        label: <Link to="/templates/agreements">Agreements</Link>,
                    },
                ],
            },
        ],
    },
];

function defaultSelectedKey(matches: UIMatch[]) {
    const lastRoute = matches[matches.length - 1];
    if (lastRoute.pathname === '/') {
        return 'dashboard'
    }

    return trimSlashes(lastRoute.pathname);
}

const SiderMenu = () => {
    const matches = useMatches();
    const selectedKey = defaultSelectedKey(matches);

    return (
        <Menu
            style={{borderRight: 0}}
            mode="inline"
            items={items}
            defaultSelectedKeys={[selectedKey!]}
        />
    );
}

export default SiderMenu;
