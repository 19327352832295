import type {Params} from 'react-router-dom';

import {user} from 'services/users';
import {roles} from 'services/roles';
import {roleAssignments} from 'services/roleAssignments';

export async function userDetailLoader(args: { params: Params<'userId'> }) {
    return Promise.all([user(args.params.userId!), roles(), roleAssignments()])
        .then((values) => {
            return {
                user: values[0],
                roles: values[1],
                roleAssignments: values[2],
            }
        })
}
