// import {Link} from 'react-router-dom';

import {Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Agreement, User, Keyed} from 'types';
import {useUserAgreements} from 'hooks/useAgreements';

const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {_id}) => {
            //return (<Link to={`/goals/${_id}`}>{text}</Link>);
            return text;
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'goalType',
    }
];

const UserAgreementsTable = (props: {
    user: User,
}) => {
    const agreements = useUserAgreements(props.user);
    const dataSource = (agreements as Keyed<Agreement>[]).map(row => {
        return Object.assign({}, row, {key: row._id});
    })

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
}

export default UserAgreementsTable;
