import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {Agreement} from 'types';
import {RootState} from 'store';

const agreementSlice = createSlice({
    name: 'agreements',
    initialState: {
        value: null as Agreement[] | null,
    },
    reducers: {
        setAgreements: (state, action: PayloadAction<Agreement[]|null>) => {
            state.value = action.payload;
        }
    }
});

export const { setAgreements } = agreementSlice.actions;
export const getAgreements = (state: RootState) => state.agreements.value;

export default agreementSlice.reducer;
