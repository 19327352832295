import React from 'react';
import {useLoaderData} from 'react-router-dom';

import {Card} from 'antd';

import NetworkComponent, {Graph} from './NetworkComponent';
import type {Team, Role} from 'types';

type GraphWithParent = Graph & { parentId: string|null, children: GraphWithParent[] }

function treeify(teams: Team[], roles: Role[]) {
    const graph: Record<string, GraphWithParent> = {}

    for (const team of teams) {
        graph[team._id] = {
            name: team.name,
            type: 'team',
            children: [],
            parentId: null,
        }
    }

    for (const team of teams) {
        if (team.parentTeam?._id) {
            graph[team.parentTeam?._id].children.push(graph[team._id]);
            graph[team._id].parentId = team.parentTeam?._id;
        }
    }

    for (const role of roles) {
        graph[role.contributesTo._id].children.push({
            name: role.name,
            type: 'role',
            value: 1,
            children: [],
            parentId: role.contributesTo._id,
        })
    }

    let top: Graph|null = null;
    for (const team of teams) {
        if (graph[team._id].parentId === null) {
            top = graph[team._id];
        }
    }
    return top as Graph;
}

const NetworkPage = () => {
    const {teams, roles} = useLoaderData() as {teams: Team[], roles: Role[]}
    const data = treeify(teams, roles);

    return (
        <Card style={{height: '100%', padding: '8px'}} styles={{body: {height: '100%', width: '100%'}}}>
            <NetworkComponent data={data} />
        </Card>
    );
}

export default NetworkPage;
