import {Layout, theme} from 'antd';

import SiderLogo from './SiderLogo';
import SiderMenu from './SiderMenu';

import styles from './MainSider.module.scss';

const MainSider = () => {
    const {token: {colorBgContainer}} = theme.useToken();

    return (
        <Layout.Sider className={styles.sider} width={300} style={{background: colorBgContainer}}>
            <SiderLogo/>
            <SiderMenu />
        </Layout.Sider>
    );
}

export default MainSider;
