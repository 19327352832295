import React from 'react';
import {useLoaderData} from 'react-router-dom';

import {Card, Descriptions} from 'antd';

import type {Template} from 'types';
import MarkdownCard from 'components/MarkdownCard';
import MainContent from 'components/MainContent';
import Object from 'components/Object';


function items(template: Template) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: template.name
        },
        {
            key: 'description',
            label: 'Description',
            children: template.description
        }
    ]
}

function findFieldDefaultValue(template: Template | null, name: string) {
    if (template) {
        for (const field of template.fields) {
            if (field._id === name) {
                return field.defaultValue;
            }
        }
    }
    return null;
}

const TemplateDetailPage = () => {
    const template = useLoaderData() as Template | null;

    const description = template?.description ? template.description : findFieldDefaultValue(template, 'description')

    return (
        <MainContent>
            <Card>
                {template ?
                    <Descriptions
                        title="Template Info"
                        items={items(template)}
                    /> : null
                }
            </Card>
            {description ? <MarkdownCard markdown={description}/> : null}
            <Object object={template} />
        </MainContent>
    );
}

export default TemplateDetailPage;
