import {Flex, Typography} from 'antd';

function ErrorDisplay(props: {
    message: string
}) {
    return (
        <Flex justify={'center'} vertical>
            <Typography.Title level={2}>An unexpected error occurred.  Please reload the page.</Typography.Title>
            <Typography.Text>{props.message}</Typography.Text>
        </Flex>
    );
}

export default ErrorDisplay;
