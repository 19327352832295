import {Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import {User} from 'types';
import {useUserRoles} from 'hooks/useUserRoles';

const columns: ColumnsType = [
    {
        title: 'User Roles',
        dataIndex: 'name',
        key: 'name'
    },
];

const UserRolesTable = (props: {
    user: User
}) => {
    const roles = useUserRoles(props.user);

    return (
        <Table
            columns={columns}
            dataSource={roles}
            pagination={false}
        />
    );
}

export default UserRolesTable;
