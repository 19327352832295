import React from 'react';
import {useLoaderData} from 'react-router-dom';

import {Card, Descriptions, Tag} from 'antd';

import type {Agreement, Template} from 'types';
import {map, statusColor} from 'utils';
import {useTemplates} from 'hooks/useTemplates';

import MarkdownCard from 'components/MarkdownCard';
import Object from 'components/Object';
import MainContent from 'components/MainContent';

import DeleteAgreementButton from './DeleteAgreementButton';


function items(goal: Agreement, templates: Record<string, Template>) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: goal.name,
            span: 3
        },
        {
            key: 'template',
            label: 'Template',
            children: goal.templateId,
            span: 3,
            render: ({_id}: { _id: string }) => {
                return templates[_id]?.name;
            }
        },
        {
            key: 'status',
            label: 'Status',
            children: goal.status,
            span: 3,
            render: (text: string) => (
                <Tag color={statusColor(text)} key={'status'}>
                    {text.toUpperCase()}
                </Tag>
            )
        }
    ]
}

const AgreementDetailPage = () => {
    const data = useLoaderData() as Agreement;
    const templates = map<Template>(useTemplates());

    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Agreement Info"
                    items={items(data, templates)}
                    column={3}
                />
            </Card>

            {data.description ? <MarkdownCard markdown={data.description} /> : null}

            <Object object={data} />
            <DeleteAgreementButton agreementId={data._id} />
        </MainContent>
    );
}

export default AgreementDetailPage;
